import { Link } from 'gatsby';
import * as React from 'react';
import { AppText, NewAppTitle, NewAppText, NewAppButton } from '../components/ui';
// import { LostPageLogo, LostPageError } from '../assets';
import { device, mobileSize } from '../utils';
import styled from 'styled-components';
import { IndexLayout } from '../layouts/';
import Helmet from 'react-helmet';
import { NavBar } from '../components/segments/NavBar';
import Wrapper from '../components/Wrapper';
import DarkFooter from '../components/segments/DarkFooter';
import { theme } from '../utils/theme';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { laptopSize } from '../utils/variables';
import LetsConnectSection from '../components/segments/LetsConnectSection';

export default () => {
  return (
    <IndexLayout>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      {/* <NotFoundContainer>
        <ContentContainer>
          <StyledHeader>
            <img src={LostPageError} />
          </StyledHeader>
          <StyledText>SORRY, THIS PAGE DOES NOT EXIST</StyledText>
          <ImageContainer>
            <Link to="/" aria-label="rapptr">
              <img src={LostPageLogo} />
            </Link>
          </ImageContainer>
        </ContentContainer>
      </NotFoundContainer> */}
      <NavBar alwaysOpaque invert />
      <PageContainer>
        <ProcessWrapper>
          <PageMainText>Extraordinary App Design and User Experiences</PageMainText>
          <SplashContainer>
            <TextContainer>
              {/* <Wrapper> */}
              <PageDescriptionText>
                Our app designers have a passion for bringing excellent, scalable digital products
                to market quickly. We build great user interfaces for mobile apps and web products
                that increase converstions, reduce maintenance costs, and create a thrilling user
                experience.
              </PageDescriptionText>
              <PageDescriptionText>
                With decades of experience in mobile app design and development, the Rapptr team
                knows how to build unique, intuitive, and engaging mobile experiences. Funded
                startups and organizations of all sizes work with Rapptr to design beautiful apps
                that delight and deliver.
              </PageDescriptionText>
            </TextContainer>
          </SplashContainer>
        </ProcessWrapper>
        <AbsDiv1 />
        <AbsDiv2 />
      </PageContainer>
      <ProcessContainer>
        <ProcessWrapper>
          <SectionTitle>Our Holistic App Design Process</SectionTitle>
          <SectionDescription>
            We approach app design with creativity, strategy, and a user-centric approach. Informed
            by careful research and testing, out team helps you build better mobile apps and digital
            experiences from start to finish.
          </SectionDescription>
        </ProcessWrapper>
      </ProcessContainer>
      <LetsConnectSection />
      <DarkFooter />
    </IndexLayout>
  );
};

const ProcessWrapper = styled.div`
  max-width: 900px;
`;

const SectionDescription = styled(NewAppText)`
  color: ${theme.MAIN_DARK_BLUE};
`;

const SectionTitle = styled(NewAppTitle)`
  color: ${theme.MAIN_DARK_BLUE};
  font-weight: bold;
`;

const ProcessContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  padding-bottom: 70px;
`;

const SplashContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContactUsButton = styled(NewAppButton)`
  margin: 0px;
  padding: 10px 12px;
  line-height: 1;
  height: 40px;
  background-color: ${theme.MAIN_DARK_BLUE};
  color: ${theme.WHITE_COLOR};
  border-radius: 7px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const LetsConnectDescription = styled(NewAppText)`
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  text-align: center;
`;

const LetsConnectText = styled(NewAppTitle)`
  font-weight: 700;
  font-size: 42px;
  line-height: 55px;
  text-align: center;
  @media ${mobileSize} {
    font-size: 35px;
    line-height: 45px;
  }
`;

const PageDescriptionText = styled(NewAppText)`
  max-width: 694px;
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 60px;
`;

const PageMainText = styled(NewAppTitle)`
  /* font-family: 'Objektiv Mk2'; */
  font-style: normal;
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  margin-bottom: 39px;
  /* max-width: 694px; */
  @media ${mobileSize} {
    font-size: 45px;
    line-height: 53px;
  }
`;

const PageLabel = styled(NewAppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const AbsDiv1 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 0 0 27vw;
  border-color: transparent transparent transparent ${theme.WHITE_COLOR};
  bottom: 0;
  left: 0;
  z-index: 1;
  @media ${laptopSize} {
    border-width: 50px 0 0 27vw;
  }
`;

const AbsDiv2 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 300px 78vw;
  border-color: transparent transparent ${theme.WHITE_COLOR} transparent;
  bottom: 0;
  right: 0;
  z-index: 1;
  @media ${laptopSize} {
    border-width: 0 0 80px 78vw;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  width: fit-content;
  color: ${theme.WHITE_COLOR};
  font-weight: bold;
  z-index: 3;
  :hover {
    color: ${theme.SKY_BLUE};
    text-decoration: none;
  }
`;

const TextContainer = styled.div`
  width: 835px;
  z-index: 2;
  @media ${laptopSize} {
    width: 100%;
  }
`;

const PageContainer = styled.div`
  position: relative;
  min-height: 650px;
  width: 100%;
  background-color: ${theme.BACKGROUND_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-top: 120px;
  @media ${laptopSize} {
    margin-top: 80px;
  }
`;

const LetsConnectDiv = styled.div`
  background-color: ${theme.MID_BLUE};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 62px 20px;
`;

const NotFoundContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(135deg, #4d5361 16%, #34373e 105%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5%;
  @media ${device.mobileL} {
    padding: 0px 15%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledHeader = styled.div`
  width: 277px;
  margin: 0px 0px 100px 0px;
  @media ${device.tablet} {
    margin: 0px 0px 100px 0px;
    width: 700px;
  }
`;

const StyledText = styled(AppText)`
  font-family: regulator-nova;
  width: 315px;
  height: 27px;
  font-size: 11px;
  font-weight: 500;
  line-height: 2.41;
  color: #ffffff;
  text-align: center;
  @media ${device.tablet}{
    width: 732px;
    height: 69px;
    font-size: 30px;
    line-height: 1.73
    letter-spacing: 3px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 200px;
  margin: 100px 0px 0px 0px;
  @media ${device.tablet} {
    width: 450px;
    margin: 150px 0px 0px 0px;
  }
`;
